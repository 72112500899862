import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker, Polyline, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./CustomMap.css";
import "leaflet-routing-machine";
import axios from "axios";

import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";

const CIRCUIT_COLORS = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#00FFFF', // Cyan
  '#FF00FF', // Magenta
  '#FFD700', // Gold
  '#008000', // Dark Green
  '#4B0082', // Indigo
];

const markerIcons = {
  charger: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  },
  depot: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  }
};

const routeColors = Array.from({ length:  10}, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`);

const RoutingControl = ({ coords, highlightedRoute }) => {
  const map = useMap();

  const processedCoordinates = [];
  if (coords && coords.lat && coords.lon) {
    for (let i = 0; i < coords.lat.length; i++) {
      if (coords.lat[i] !== null && coords.lon[i] !== null) {
        processedCoordinates.push([coords.lat[i], coords.lon[i]]);
      }
    }
  }

  useEffect(() => {
    if (!map || processedCoordinates.length < 2) return;

    try {
      const polyline = L.polyline(processedCoordinates, {
        color: routeColors[Math.floor(Math.random() * routeColors.length)],
        weight: 6,
        opacity: 1,
        smoothFactor: 1,
      });

      polyline.addTo(map);

      const markers = [];

      if (processedCoordinates.length > 0) {
        // Start coordinate: White dot
        markers.push(
          L.circleMarker(processedCoordinates[0], {
            radius: 6,
            color: "#000000",
            weight: 2,
            fillColor: "#ffffff",
            fillOpacity: 1,
          }).addTo(map)
        );
      }

      if (processedCoordinates.length > 1) {
        // Last stop: Red marker
        markers.push(
          L.marker(processedCoordinates[processedCoordinates.length - 1], {
            icon: L.icon({
              iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
              shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            }),
          }).addTo(map)
        );
      }

      // Only fit bounds if this is a highlighted route
      if (highlightedRoute) {
        const bounds = polyline.getBounds();
        map.fitBounds(bounds, {
          padding: [100, 100],
          maxZoom: 12
        });
      }

      return () => {
        map.removeLayer(polyline);
        markers.forEach((marker) => map.removeLayer(marker));
      };
    } catch (error) {
      console.error("Error setting up polyline:", error);
    }
  }, [map, coords, highlightedRoute]);

  return null;
};

export const CustomMap = ({
  coordinates,
  selectedRoutes,
  selectedEntities = {},
  circuitData = {},
  onMarkerClick,
  highlightedRoute,
}) => {
  const [routeCheckbox, setRouteCheckbox] = useState(false);
  const [depotCheckbox, setDepotCheckbox] = useState(false);
  const [chargerCheckbox, setChargerCheckbox] = useState(false);

  const [processedRoutes, setProcessedRoutes] = useState([]);
  const [chargers, setChargers] = useState([]);
  const [depots, setDepots] = useState([]);

  const [satelliteView, setSatelliteView] = useState(true);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    async function fetchSelectedRoutes() {
      if (!routeCheckbox) {
        setProcessedRoutes([]);
        return;
      }

      try {
        const response = await axios.get("http://localhost:8000/api/getprocessedroutes/", {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (response.data && response.data.length > 0) {
          const routesFromAPI = response.data;
          const processedRoutesData = [];

          // Extract all selected route ids (as strings)
          const selectedRouteIds = Object.entries(selectedRoutes)
            .filter(([_, isSelected]) => isSelected)
            .map(([id]) => id);

          for (const routeId of selectedRouteIds) {
            const currentRoute = routesFromAPI.find(
              (route) => route.id.toString() === routeId
            );

            if (currentRoute) {
              try {
                const rawResponse = await axios.get(`http://localhost:8000/api/getrawroute/`, {
                  params: { route_id: currentRoute.raw_route },
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                });

                if (rawResponse.data?.links?.[0]?.coordinates) {
                  const coords = rawResponse.data.links[0].coordinates;
                  processedRoutesData.push({
                    lat: coords.map((coord) => coord[1]),
                    lon: coords.map((coord) => coord[0]),
                    routeId: currentRoute.id,
                  });
                }
              } catch (error) {
                console.error(`Error fetching raw route for id ${routeId}:`, error);
              }
            }
          }

          setProcessedRoutes(processedRoutesData);
        }
      } catch (error) {
        console.error("Error fetching routes: ", error);
        setProcessedRoutes([]);
      }
    }
    fetchSelectedRoutes();
  }, [routeCheckbox, selectedRoutes, authToken]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getcharger/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setChargers(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getdepot/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log("Depot data received:", response.data);
        setDepots(response.data);
      })
      .catch((error) => {
        console.log("Error fetching depots:", error);
      });
  }, [authToken]);

  const getMarkerIcon = (coord, type) => {
    const isSelected = selectedEntities[coord.depot_id];
    return isSelected ? markerIcons[type].selected : markerIcons[type].default;
  };

  const renderCircuitLines = () => {
    const lines = [];
    Object.entries(selectedEntities).forEach(([depotId, isSelected]) => {
      if (isSelected && circuitData[depotId]) {
        circuitData[depotId].forEach((circuit, circuitIndex) => {
          const circuitColor = CIRCUIT_COLORS[circuitIndex % CIRCUIT_COLORS.length];
          circuit.geometry.coordinates.forEach((lineString, lineIndex) => {
            const positions = lineString.map(coord => [coord[1], coord[0]]);
            lines.push(
              <Polyline
                key={`circuit-${depotId}-${circuitIndex}-${lineIndex}`}
                positions={positions}
                color={circuitColor}
                weight={3}
                opacity={0.8}
                eventHandlers={{
                  click: (e) => {
                    L.DomEvent.stopPropagation(e);
                  },
                }}
              >
                <Popup>
                  <div className="circuit-popup">
                    <h5>Circuit: {circuit.circuit_name}</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td>Capacity</td>
                          <td>{circuit.capacity} kW</td>
                        </tr>
                        <tr>
                          <td>Voltage</td>
                          <td>{circuit.circuit_voltage} kV</td>
                        </tr>
                        <tr>
                          <td>Substation</td>
                          <td>{circuit.substation_name?.replace(/Kv/g, 'kV')}</td>
                        </tr>
                        <tr>
                          <td>Last Updated</td>
                          <td>{new Date(circuit.date_last_update).toLocaleDateString()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Popup>
              </Polyline>
            );
          });
        });
      }
    });
    return lines;
  };

  if (!window.L) {
    return <div>Loading map library...</div>;
  }

  return (
    <div className="map-container">
      <MapContainer
        key={satelliteView ? "satellite" : "osm"} // This will change whenever the satelliteView state changes
        center={[33.9, -117.5]}
        zoom={10}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        /> */}

        {/* <TileLayer
          url="https://{s}.tile.stamen.com/terrain/{z}/{x}/{y}.jpg"
          attribution='&copy; <a href="https://stamen.com/">Stamen Design</a>, <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        /> */}

      <TileLayer
        url={satelliteView ? 
          "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" : 
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        }
        attribution={satelliteView ? 
          '&copy; <a href="https://www.esri.com/en-us/arcgis/products/arcgis-online/overview">Esri</a>' : 
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      />
        {renderCircuitLines()}

        {/* Render depot markers */}
        {depotCheckbox && depots.map((depot, index) => (
          <Marker
            key={`depot-${index}-${depot.lat}-${depot.lon}`}
            position={[depot.lat, depot.lon]}
            icon={getMarkerIcon(depot, 'depot')}
            zIndexOffset={selectedEntities[depot.depot_id] ? 1000 : 0}
            eventHandlers={{
              click: () => onMarkerClick(depot.depot_id, true),
            }}
          >
            <Popup>
              <div>
                <h4>Depot</h4>
                {depot.name && <p>Name: {depot.name}</p>}
                <p>ID: {depot.depot_id}</p>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* Render charger markers */}
        {chargerCheckbox && chargers.map((charger, index) => (
          <Marker
            key={`charger-${index}-${charger.lat}-${charger.lon}`}
            position={[charger.lat, charger.lon]}
            icon={getMarkerIcon(charger, 'charger')}
            zIndexOffset={selectedEntities[charger.cs_id] ? 1000 : 0}
            eventHandlers={{
              click: () => onMarkerClick(charger.cs_id, true),
            }}
          >
            <Popup>
              <div>
                <h4>Charging Station</h4>
                <p>ID: {charger.cs_id}</p>
                <p>{charger.owner} - {charger.address}</p>
                <p>Chargers: {charger.chargers}</p>
                <p>Charging capacity: {charger.powers}</p>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* Route Display: map over each processed route */}
        {routeCheckbox && processedRoutes && processedRoutes.length > 0 &&
          processedRoutes.map(route => (
            <RoutingControl
              key={`route-${route.routeId}`}
              coords={route}
              highlightedRoute={highlightedRoute}
              color="#2196F3"
            />
          ))
        }
      </MapContainer>
      <div className="map-overlay">
        <h3>Include</h3>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={routeCheckbox}
            onChange={(e) => setRouteCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">Routes</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={depotCheckbox}
            onChange={(e) => setDepotCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">
            Depots
            <img 
              src= "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
              alt="Depot"
              style={{ width: "10px", height: "15px", marginLeft: "5px" }}
            />
          </span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={chargerCheckbox}
            onChange={(e) => setChargerCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">
            Charging Stations
            <img 
              src= "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"
              alt="Chargers"
              style={{ width: "10px", height: "15px", marginLeft: "5px" }}
            />
          </span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={satelliteView}
            onChange={(e) => setSatelliteView(e.target.checked)}
          />
          <span className="map-checkbox-description">Satellite View</span>
        </label>
      </div>
    </div>
  );
};