import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import axios from "axios";

export const AnalyticsMap = ({ routes, routeFuelData, selectedRouteId }) => {
  // routes: an array of route objects containing at least { id, raw_route }
  // routeFuelData: an object mapping route ids to fuel data (e.g. { is_electric: 1 } for electric)
  // selectedRouteId: the route to display (if provided, only that route is shown)
  const [analyticsRoutes, setAnalyticsRoutes] = useState([]);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    const fetchRoutes = async () => {
      if (routes && routes.length > 0) {
        const fetchedRoutes = [];
        for (const route of routes) {
          try {
            const response = await axios.get("http://localhost:8000/api/getrawroute/", {
              params: { route_id: route.raw_route },
              headers: {
                Authorization: `Token ${authToken}`,
              },
            });

            if (
              response.data &&
              response.data.links &&
              response.data.links[0] &&
              response.data.links[0].coordinates
            ) {
              const coords = response.data.links[0].coordinates;
              // Convert coordinates from [lon, lat] to [lat, lon] for Leaflet.
              const positions = coords.map(coord => [coord[1], coord[0]]);
              fetchedRoutes.push({
                id: route.id,
                positions,
              });
            }
          } catch (error) {
            console.error(`Error fetching raw route for route ${route.id}:`, error);
          }
        }
        setAnalyticsRoutes(fetchedRoutes);
      } else {
        setAnalyticsRoutes([]);
      }
    };

    fetchRoutes();
  }, [routes]);

  // Determine the color for a route based on its fuel data.
  // Green for electric (is_electric === 1), otherwise blue.
  const getColorForRoute = (routeId) => {
    if (routeFuelData && routeFuelData[routeId] && routeFuelData[routeId].is_electric === 1) {
      return "#00FF00"; // Green
    }
    return "#FF0000"; // Red
  };

  // If a selectedRouteId is provided, filter analyticsRoutes to displayed only that route.
  const routesToDisplay = selectedRouteId
    ? analyticsRoutes.filter(route => route.id.toString() === selectedRouteId.toString())
    : analyticsRoutes;

  return (
    <div className="map-container">
      <MapContainer
        center={[33.9, -117.5]}
        zoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; OpenStreetMap contributors'
        />
        {routesToDisplay.map((route) => (
          <Polyline
            key={`analytics-route-${route.id}`}
            positions={route.positions}
            color={getColorForRoute(route.id)}
            weight={4}
            opacity={0.8}
          />
        ))}
      </MapContainer>
    </div>
  );
};