import React, { useState, useEffect, useRef } from "react";
import "./RoutingTable.css";
import axios from "axios";

export const RoutingTable = ({
  selectedRoutes,
  onRouteSelect,
  lastMapSelection,
  tableData,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const rowRefs = useRef({});

  const handleCheckboxChange = (routeId) => {
    onRouteSelect(routeId, false);
  };

  const handleViewClick = (routeId) => {
    // Only update the selected state, don't toggle it
    if (!selectedRoutes[routeId]) {
      onRouteSelect(routeId, true);
    }
  };

  const scrollToRow = (route_id) => {
    if (rowRefs.current[route_id]) {
      setHighlightedRow(route_id);
      rowRefs.current[route_id].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setTimeout(() => {
        setHighlightedRow(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (lastMapSelection) {
      scrollToRow(lastMapSelection);
    }
  }, [lastMapSelection]);

  if (!tableData || tableData.length === 0) {
    return <p>No routes available</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="charger-header">Your Routes</h3>
      </div>
      <table className="table">
        <thead>
          {/*TODO: Modify route columns, headers should be the same 
          as the entries in the database. i.e. trj_id, truck*/}
          <tr className="table-header">
            <th></th>
            <th>Route ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/*TODO: Modify assignment to routes, not depots */}
          {tableData.map((route, index) => (
            <tr
              ref={(el) => (rowRefs.current[route.id] = el)}
              className={`table-content ${
                selectedRoutes[route.id] ? "selected" : ""
              } ${highlightedRow === route.id ? "highlighted" : ""}`}
              key={route.id}
            >
              <td>
                <input
                  type="checkbox"
                  checked={!!selectedRoutes[route.id]}
                  onChange={() => handleCheckboxChange(route.id)}
                  className="charger-checkbox"
                />
              </td>
              <td>{route.route_id}</td>
              <td>
                <button 
                  className="custom-btn" 
                  onClick={() => handleViewClick(route.id)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
