import "../../App.css";
import React, { useState, useEffect } from "react";
import './analytics.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { RunHistoryTable } from "../../components/pro_calculator/tables/RunHistoryTable";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, BarChart, Bar, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { AnalyticsMap } from "../../components/pro_calculator/AnalyticsMap";

function Analytics() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState(null);
  const [truckData, setTruckData] = useState({});
  const [error, setError] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [highlightMapRoute, setHighlightMapRoute] = useState(null);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    axios.get("http://localhost:8000/api/getlatestoptimization", {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => {
        setOptimizationResults(response.data);
      })
      .catch((error) => {
        console.error("Error", error);
      })
  }, [authToken]);

  useEffect(() => {
    const fetchTruckData = async () => {
      try {
        const response = await axios.get("http://localhost:8000/api/gettruckdata/"); // Update the URL as needed
        setTruckData(response.data);
      } catch (err) {
        setError("Failed to fetch truck data.");
        console.error(err);
      }
    };

    fetchTruckData();
  }, []);

  useEffect(() => {
    axios.get("http://localhost:8000/api/getprocessedroutes/", {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => {
        setRoutes(response.data);
      })
      .catch((error) => {
        console.error("Error", error);
      })
  }, [authToken]);

  console.log("ROUTES", routes);

  if (!userData || !optimizationResults || !truckData || !routes) {
    return <p>Loading ... </p>;
  }

  const handleRouteChange = (e) => {
    setSelectedRoute(e.target.value);
  }

  // When routes are loaded (from getprocessedroutes), use the first route's id as default.
  const initialHighlightedRoute = (routes && routes.length > 0) ? routes[0].id : null;

  const handleHighlightedRouteChange = (e) => {
    // Value will match one of the route IDs
    setHighlightMapRoute(e.target.value);
  };

  console.log(authenticated, authToken);
  console.log(userData);

  console.log("Optimization data", optimizationResults);

  // Extract optimization results into variables
  const dieselCosts = optimizationResults.data.results.cost_analysis.daily_costs.diesel;
  const electricCosts = optimizationResults.data.results.cost_analysis.daily_costs.electric;
  const chargingSchedules = optimizationResults.data.results.visualization_data.charging_schedules;
  const routeFuelData = {};
  Object.values(optimizationResults.data.results.operational_recommendations.route_electrification.routes).forEach((route) => {
    routeFuelData[route.id] = { is_electric: route.is_electric };
  });

  // Default to the first route if no route is selected
  const initialRoute = selectedRoute || Object.keys(chargingSchedules)[0];
  const schedule = chargingSchedules[initialRoute];
  const scheduleData = Object.entries(schedule).map(([time, data]) => ({
    name: time,
    depot: data.depot_load,
    public: data.public_load,
  }));

  const chartData = [
    {
      category: "Energy Cost",
      Diesel: dieselCosts.energy_cost.reduce((a, b) => a + b, 0),
      Electric:
        electricCosts.energy_costs.depot_energy.reduce((a, b) => a + b, 0) +
        electricCosts.energy_costs.depot_demand.reduce((a, b) => a + b, 0) +
        electricCosts.energy_costs.public_charging.reduce((a, b) => a + b, 0),
    },
    {
      category: "Vehicle Cost",
      Diesel: dieselCosts.vehicle_cost.reduce((a, b) => a + b, 0),
      Electric: electricCosts.vehicle_cost.reduce((a, b) => a + b, 0),
    },
    {
      category: "Insurance Cost",
      Diesel: dieselCosts.insurance_cost.reduce((a, b) => a + b, 0),
      Electric: electricCosts.insurance_cost.reduce((a, b) => a + b, 0),
    },
    {
      category: "Maintenance Cost",
      Diesel: dieselCosts.maintenance_cost.reduce((a, b) => a + b, 0),
      Electric: electricCosts.maintenance_cost.reduce((a, b) => a + b, 0),
    },
    {
      category: "Penalties",
      Diesel: dieselCosts.non_ze_penalties.reduce((a, b) => a + b, 0),
      Electric: 0, // No penalties in electric costs
    },
  ];

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label">Analytics</h1>
        <div className="container-flex-row">
          <div className="dashboard-container route-results">
            <div className="dashboard-container overview">
              <h3>Overview</h3>
              <p><span className="category-title">Case Name</span>: {optimizationResults.case_name}</p>
              <p><span className="category-title">Timestamp</span>: {new Date(optimizationResults.timestamp).toLocaleString()}</p>
              <p><span className="category-title">Daily Total Cost</span>: ${optimizationResults.data.summary.daily_total.toFixed(2)}</p>
              <p><span className="category-title">Annual Total Cost</span>: ${optimizationResults.data.summary.annual_total.toLocaleString()}</p>
              <p><span className="category-title">Upfront Cost</span>: ${optimizationResults.data.summary.upfront_costs.vehicles.toLocaleString()} (Vehicles), ${optimizationResults.data.summary.upfront_costs.infrastructure.toLocaleString()} (Infrastructure)</p>
            </div>
            <p>Out of {optimizationResults.data.fleet_metrics.total_routes} routes total, {optimizationResults.data.fleet_metrics.electrified_routes} are electrified and {optimizationResults.data.fleet_metrics.diesel_routes} are diesel</p>
          </div>
          <div className="dashboard-container vehicle-recommendations">
            {Object.values(optimizationResults.data.results.operational_recommendations.route_electrification.routes).map((route) => (
              <div key={route.route_id}>
                <li key={route.route_id} style={{ marginBottom: "10px" }}>
                  <strong>Route {route.route_id}</strong>:
                  <ul>
                    <li>Status: {route.is_electric === 1 ?
                      <span style={{ color: "green" }}>Electrified</span> : <span style={{ color: "red" }}>Non-Electric</span>}</li>
                    <li>Recommended Truck: {route.is_electric === 1 ? `${truckData[route.selected_model].make} ${truckData[route.selected_model].model}` : "N/A"}</li>
                  </ul>
                </li>
              </div>
            ))}
          </div>
        </div>
        <div className="container-flex-row">
          <div className="dashboard-container cost-breakdown">
            <h3>Cost Breakdown</h3>
            <h3>Total Daily Cost</h3>
            <ResponsiveContainer width="80%" height={400}>
              <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip formatter={(value) => `$${value.toFixed(2)}`} />
                <Legend />
                <Bar dataKey="Diesel" fill="#ff6384" />
                <Bar dataKey="Electric" fill="#36a2eb" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div>
          <div className="dashboard-container charging-schedule">
            <div className="dropdown-container-custom">
              <h3>Select Charging Schedule</h3>
              <select value={selectedRoute || initialRoute} onChange={handleRouteChange}>
                {Object.keys(chargingSchedules).map((routeId) => (
                  <option key={routeId} value={routeId}>
                    Route {parseInt(routeId) + 1}
                  </option>
                ))}
              </select>
            </div>
            <h3>Charging Schedule for Route {parseInt(initialRoute) + 1}</h3>
            <LineChart width={600} height={300} data={scheduleData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" label={{ value: "Time", position: "insideBottom" }} />
              <YAxis label={{ value: "kW", angle: -90, position: "insideLeft" }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="depot" stroke="#8884d8" />
              <Line type="monotone" dataKey="public" stroke="#82ca9d" />
            </LineChart>
          </div>
        </div>
        <div className="map-dashboard-container">
          <div className="dropdown-container-custom">
            <h3>Select Route to View</h3>
            <select
              value={highlightMapRoute || initialHighlightedRoute}
              onChange={handleHighlightedRouteChange}
            >
              {routes.map((route) => (
                <option key={route.id} value={route.id}>
                  Route {route.id}
                </option>
              ))}
            </select>
          </div>
          <AnalyticsMap
            routes={routes}
            routeFuelData={routeFuelData}
            selectedRouteId={highlightMapRoute || initialHighlightedRoute}
          />
        </div>
        <div className="dashboard-container run-history">
          <RunHistoryTable />
        </div>
      </div>
    </div>
  );
}

export default Analytics;
