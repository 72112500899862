import React, { useState } from 'react';
import "../../App.css";
import Popup from "reactjs-popup";
import "./addButton.css";
import { GoPlus } from "react-icons/go";
import { CgAdd } from "react-icons/cg";
import { FiMinusCircle } from "react-icons/fi";
import AddressInput from './addressInput';

export const AddButton = ({ entity, position, fields, onSubmit, dynamicAddRoute = false }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (fieldId, value) => {
    setFormData(prev => ({
      ...prev,
      [fieldId]: value
    }));
    // Clear error when user types
    if (errors[fieldId]) {
      setErrors(prev => ({
        ...prev,
        [fieldId]: null
      }));
    }
  };

  const addStop = () => {
    setFormData((prev) => ({
      ...prev,
      stops: [...(prev.stops || []), ""],
    }));
  };

  const removeStop = (index) => {
    setFormData((prev) => ({
      ...prev,
      stops: prev.stops.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    fields.forEach(field => {
      if (!formData[field.id]) {
        newErrors[field.id] = `${field.name} is required`;
      }
      if (field.type === 'number' && isNaN(parseFloat(formData[field.id]))) {
        newErrors[field.id] = `${field.name} must be a number`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (close) => {
    if (validateForm()) {
      try {
        await onSubmit(formData);
        setFormData({}); // Clear form
        close();
      } catch (error) {
        console.error('Error submitting form:', error);
        // You could set a general error state here if needed
      }
    }
  };

  const renderInput = (field) => {
    if (field.type === 'address') {
      return (
        <div>
          <AddressInput
            onAddressSelect={(address) => handleInputChange(field.id, address)}
          />
          {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
        </div>
      );
    }

    return (
      <div>
        <input
          type={field.type}
          id={field.id}
          className="popup-input"
          value={formData[field.id] || ''}
          onChange={(e) => handleInputChange(field.id, e.target.value)}
        />
        {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
      </div>
    );
  };

  return (
    <div className="btn-container">
      <Popup trigger={
        <button className={`add-button-custom ${position}`}><GoPlus size={19} /> Add a {entity}</button>
      } modal nested>
        {close => (
          <div className="popup-container">
            <div className="close-container">
              <button className="close" onClick={() => close()}>&times;</button>
            </div>
            <div className="popup-content">
              <h3 className="popup-title">Add a {entity}</h3>
              <div className="popup-input-group-container">
                {fields.map((field) => (
                  <div className="popup-input-group" key={field.id}>
                    <div className="popup-input-group-label">
                      {field.name}
                    </div>
                    {renderInput(field)}
                  </div>
                ))}

                {dynamicAddRoute && (
                  <div className="popup-input-group">
                    {formData.stops?.map((stop, index) => (
                      <div key={index} className='stop-row'>
                        <p className='popup-input-group-label'> Stop {index + 1}</p>
                        <AddressInput
                          onAddressSelect={(address) => handleInputChange(index, address)}
                        />
                        {errors[index] && <div className="error-message">{errors[index]}</div>}
                        <input
                          className='popup-input-idle-time'
                          type="number"
                          placeholder='Idle time...'
                          value={formData.idle_times?.[index] || ''}
                          onChange={(e) => {
                            const updatedTimes = [...(formData.idle_times || [])];
                            updatedTimes[index] = e.target.value;
                            setFormData((prev) => ({ ...prev, idle_times: updatedTimes }));
                          }}
                        />
                        <button className="remove-route-btn" type="button" onClick={() => removeStop(index)}><FiMinusCircle size={23} /></button>
                      </div>
                    ))}
                  </div>
                )}

                {
                  dynamicAddRoute && (!formData.stops || formData.stops.length <= 6) && (
                    <button className="add-route-btn" type="button" onClick={addStop}>< CgAdd size={24} /> Add a stop </button>
                  )
                }
              </div>
            </div>
            <div className="add-button-container">
              <button className="add-button-popup" onClick={() => handleSubmit(close)}>
                <GoPlus size={19} /> Add {entity}
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};