import React, { useState, useEffect } from "react";
import "./FleetTable.css";
import axios from "axios";

export const FleetTable = ({ onTruckSelect }) => {
  const [tableData, setTableData] = useState(null);
  const [fleetNames, setFleetNames] = useState(null);
  const [currentFleet, setCurrentFleet] = useState(null);
  const [selectedTrucks, setSelectedTrucks] = useState({});

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getallfleet/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setFleetNames(response.data);
        if (response.data.length > 0) {
          setCurrentFleet(response.data[0].name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  console.log("fleet names", fleetNames);

  useEffect(() => {
    if (fleetNames) {
      axios
        .get(`http://localhost:8000/api/getfleet/?name=${currentFleet}`, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => {
          setTableData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authToken, currentFleet, fleetNames]);

  const handleFleetChange = (e) => {
    setCurrentFleet(e.target.value);
  };

  const handleCheckboxChange = (truckId) => {
    setSelectedTrucks((prev) => {
      const newState = {
        ...prev,
        [truckId]: !prev[truckId],
      };

      if (newState[truckId]) {
        onTruckSelect(truckId);
      } else {
        onTruckSelect(null);
      }

      return newState;
    });
  };

  if (!tableData || !tableData.trucks) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="dropdown-container">
        <label htmlFor="fleet-select" className="fleet-header">
          {" "}
          Your Fleets:{" "}
        </label>
        <select
          id="fleet-select"
          value={currentFleet}
          onChange={handleFleetChange}
        >
          {fleetNames.map((fleet) => (
            <option key={fleet.name} value={fleet.name}>
              {fleet.name}
            </option>
          ))}
        </select>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>ID</th>
            <th>Make</th>
            <th>Model</th>
            <th>Year</th>
            <th>Odometer</th>
            <th>Residual Value</th>
            <th>Status</th>
            <th>Fuel Type</th>
            <th>Daily Mileage</th>
            <th>Route</th>
          </tr>
        </thead>
        <tbody>
          {tableData.trucks.map((truck, index) => (
            <tr className="table-content" key={`${truck.truck_id}-${index}`}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedTrucks[truck.truck_id] || false}
                  onChange={() => handleCheckboxChange(truck.truck_id)}
                  className="truck-checkbox"
                />
              </td>
              <td>{index + 1}</td>
              <td>{truck.make}</td>
              <td>{truck.model}</td>
              <td>{truck.year}</td>
              <td>{truck.odometer}</td>
              <td>${truck.price}</td>
              <td
                className={
                  truck.status === "Active"
                    ? "status-active"
                    : "status-inactive"
                }
              >
                {truck.status}
              </td>
              <td>{truck.fuel_type}</td>
              <td>{truck.daily_mileage}</td>
              <td>
                <button className="add-button">+ Add</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
