import "../../App.css";
import React, { useState, useEffect } from "react";
import "./routing.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { AddButton } from "../../components/general/addButton";
import { RoutingTable } from "../../components/pro_calculator/tables/RoutingTable";

function Routing() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRoutes, setSelectedRoutes] = useState({});
  const [lastMapSelection, setLastMapSelection] = useState(null);
  const [coordinates, setCoordinates] = useState({});
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getroutes/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log("GETROUTES", response);
        setRoutes(response.data)
        }
      )
      .catch((error) => {
        console.error("Error fetching routes:", error);
        setRoutes([]);
      });
  }, [authToken]);

  const handleRouteSelect = async (routeId, fromView = false) => {
    // If it's from the view button, we want to select without toggling
    const isSelecting = fromView ? true : !selectedRoutes[routeId];
    
    // If it's from view button and already selected, don't do anything
    if (fromView && selectedRoutes[routeId]) {
      return;
    }

    setSelectedRoutes((prev) => ({
      ...prev,
      [routeId]: isSelecting,
    }));

    if (fromView) {
      setLastMapSelection(routeId);
    }

    if (isSelecting) {
      try {
        const selectedRoute = routes.find(route => route.id === routeId);
        if (!selectedRoute) {
          console.error("Route not found in routes list");
          return;
        }

        const response = await axios.get(`http://localhost:8000/api/getrawroute/`, {
          params: { route_id: selectedRoute.raw_route },
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });
        console.log("RAW ROUTE DATA", response.data);
        
        if (response.data && response.data.links && response.data.links[0] && response.data.links[0].coordinates) {
          console.log("Setting coordinates with coordinates:", response.data.links[0].coordinates);
          // Convert the coordinates array to the format we need
          const routeCoordinates = response.data.links[0].coordinates.map(([lon, lat]) => ({
            lat: lat,
            lon: lon
          }));
          setCoordinates(routeCoordinates);
        } else {
          console.error("Unexpected data structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching raw route data:", error);
      }
    }
  };

  const handleAddRoute = async (formData) => {
    try {
      const stops = [];
      
      // Gather the stops into an array 
      Object.keys(formData).forEach((key) => {
        if (!isNaN(key)) {
          stops[parseInt(key, 10)] = formData[key];
        }
      })

      stops.unshift(formData.origin);

      const routeData = {
        start_time: formData.start_time,
        stops: stops,
        route_name: formData.route_name,
        idle_times: formData.idle_times,
      };

      console.log("FORM DATA", formData, routeData);

      const response = await axios.post(
        "http://localhost:8000/api/addroute/",
        routeData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccessMessage(response.data.message);
      setError("");

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setSuccessMessage("");
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        "Error adding route";
      setError(errorMessage);
      console.error("Error adding route:", error);
    }
  };

  const route_fields = [
    { name: "Route name", type: "string", id: "route_name" },
    { name: "Origin", type: "address", id: "origin"},
    { name: "Departure time", type:"time", id: "start_time" }
  ];

  if (!userData) {
    return <p>Loading ... </p>;
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <h1 className="title-label">Routes</h1>
        </div>
        <div className="map-dashboard-container">
          <CustomMap
            coordinates={coordinates}
            selectedRoutes={selectedRoutes}
            onMarkerClick={(routeId) => handleRouteSelect(routeId, true)}
          />
        </div>
        <div className="dashboard-container">
          <RoutingTable
            selectedRoutes={selectedRoutes}
            onRouteSelect={handleRouteSelect}
            lastMapSelection={lastMapSelection}
            tableData={routes}
          />
        </div>
        <AddButton
          entity={"route"}
          position={"bottom-left"}
          fields={route_fields}
          onSubmit={handleAddRoute}
          dynamicAddRoute={true}
        />
      </div>
    </div>
  );
}

export default Routing;
