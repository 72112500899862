import "../../App.css";
import './parameters.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import LineChartComponent from "../../components/pro_calculator/LineChart";
import { ParameterInput } from "../../components/pro_calculator/parameterinput";
import { LoadingSpinner } from "../../components/general/LoadingSpinner";

function Parameters() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [trucks, setTrucks] = useState(null);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState(null);

  // Handling advanced parameters
  // Daily costs are not included in the list, they are calculated under the hood
  const parameterGroups = [
    {
      category: "Diesel Truck Cost",
      inputs: [
        { label: "Diesel price", description: "Purchase price of diesel truck", id: "dieselTruckCost", defaultValue: 150000 },
        { label: "Diesel life", description: "Expected operational life in years", id: "dieselLife", defaultValue: 12 },
      ],
    },
    {
      category: "Fuel and Energy",
      inputs: [
        { label: "Fuel cost", description: "Cost per gallon of diesel", id: "fuelCost", defaultValue: 4.5 },
        { label: "Diesel MPG", description: "MPG (miles per gallon) of diesel trucks", id: "MPG", defaultValue: 6.5 },
        { label: "Demand charge", description: "Monthly power demand charge", id: "demandCharge", defaultValue: 0.0 },
        { label: "Public charging cost", description: "Additional cost per kWh at public stations", id: "publicChargingCost", defaultValue: 0.2 },
      ],
    },
    {
      category: "Infrastructure",
      inputs: [
        { label: "Depot construction cost", description: "Depot charging construction cost", id: "depotConstCost", defaultValue: 250000 },
        { label: "Depot life", description: "Expected life of charging infrastructure in years", id: "depotLife", defaultValue: 20 },
        { label: "Connection cost", description: "Cost per mile for circuit connection", id: "connectionCost", defaultValue: 150000 },
      ],
    },
    {
      category: "Penalties",
      inputs: [
        { label: "Non ZEV penalty", description: "Daily penalty for non-zero emission trucks", id: "nonZEV", defaultValue: 20 },
      ],
    },
    {
      category: "Maintenance",
      inputs: [
        { label: "Diesel maintenance", description: "Cost per mile for maintenance (diesel)", id: "dieselMaintenance", defaultValue: 0.21 },
        { label: "Electric maintenance", description: "Cost per mile for maintenance (electric)", id: "electricMaintenance", defaultValue: 0.1 },
      ],
    },
    {
      category: "Insurance",
      inputs: [
        { label: "Diesel insurance cost", description: "Annual insurance cost (diesel)", id: "dieselInsuranceAnnual", defaultValue: 15000 },
        { label: "Electric insurance cost", description: "Annual insurance cost (electric)", id: "electricInsuranceAnnual", defaultValue: 15000 },
      ],
    },
  ]

   // Initialize the parameters
   const initializeDefaultParameters = (groups) => {
    const defaults = {};
    groups.forEach((group) => {
      group.inputs.forEach((input) => {
        defaults[input.id] = input.defaultValue || "0"; // Use defaultValue if defined
      });
    });
    return defaults;
  };

  const [parameters, setParameters] = useState(initializeDefaultParameters(parameterGroups));

  const initialEnergyChargeData = [
    { x: 0, y: 0.16056 },
    { x: 1, y: 0.16056 },
    { x: 2, y: 0.16056 },
    { x: 3, y: 0.16056 },
    { x: 4, y: 0.16056 },
    { x: 5, y: 0.16056 },
    { x: 6, y: 0.16056 },
    { x: 7, y: 0.16056 },
    { x: 8, y: 0.16056 },
    { x: 9, y: 0.16056 },
    { x: 10, y: 0.16056 },
    { x: 11, y: 0.16056 },
    { x: 12, y: 0.16056 },
    { x: 13, y: 0.16056 },
    { x: 14, y: 0.16056 },
    { x: 15, y: 0.16056 },
    { x: 16, y: 0.59779 },
    { x: 17, y: 0.59779 },
    { x: 18, y: 0.59779 },
    { x: 19, y: 0.59779 },
    { x: 20, y: 0.59779 },
    { x: 21, y: 0.16056 },
    { x: 22, y: 0.16056 },
    { x: 23, y: 0.16056 },
  ];

  const [energyChargeData, setEnergyChargeData] = useState(initialEnergyChargeData);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          setAuthenticated(false);
          navigate("/login");
        }
      });
    return () => {
      source.cancel("Component unmounted, request canceled");
    };
  }, [navigate, authToken]);

  useEffect(() => {
    axios.get(
      "http://localhost:8000/api/gettruckdata/",
    )
      .then((response) => {
        setTrucks(response);
      })
      .catch((error) => {
        console.error(error);
      })
  }, [])

  console.log(trucks);

  if (!userData || !trucks) {
    return <p>Loading ... </p>;
  }

  // Handlers
  const handleEnergyChargeDataUpdate = (newData) => {
    setEnergyChargeData(newData);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((rowId) => rowId !== id) : [...prevSelected, id]
    );
  };

  const handleInputChange = (id, value) => {
    setParameters((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // TOU preprocessing
  const expandEnergyChargeData = (data) => {
    // Maps 1-hour increment to 15-minute increment
    return data.reduce((result, { x, y }) => {
      for (let i = 0; i < 4; i++) {
        result[x * 4 + i] = y; // Add key-value pair to the result
      }
      return result;
    }, {});
  };

  // Optimization got moved to this page for the time being
  const handleOptimize = async (e) => {
    // TODO: save truck choices 
    const selectedTruckIDs = selectedRows;

    // TODO: save the TOU rates to the backend
    // TOU in frontend time increments is 1 hour, TOU needed for backend is in 15 minute increments
    // Need to do some preprocessing
    const expandedEnergyCharges = expandEnergyChargeData(energyChargeData);

    console.log(expandedEnergyCharges)
    // Call optimization in backend, sending truck ID and TOU rates through as well
    e.preventDefault();
    setLoadingOverlay(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/api/optimize-temp0/",
        {
          case_name: 'test',
          selected_trucks: selectedTruckIDs,
          TOU: expandedEnergyCharges,
          parameters: parameters,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setOptimizationResults(response.data);
      console.log("response", response);
      console.log(optimizationResults);
      navigate("/analytics");
    } catch (error) {
      console.log("error", error);
      return;
    }
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <h1 className="title-label">Parameters</h1>
        </div>
        <div className="table-preset-selection">
          <table className="truck-presets table">
            <colgroup>
              <col style={{ width: "40vh" }} />
              <col style={{ width: "20vh" }} />
              <col style={{ width: "20vh" }} />
              <col style={{ width: "20vh" }} />
              <col style={{ width: "25vh" }} />
              <col style={{ width: "20vh" }} />
              <col style={{ width: "20vh" }} />
            </colgroup>
            <thead>
              <tr className="truck-table-header">
                <th>Select a vehicle</th>
                <th>Make</th>
                <th>Model</th>
                <th>Vehicle MSRP</th>
                <th>Battery capacity (kWH)</th>
                <th>Range at full load (miles)</th>
                <th>Incentives</th>
              </tr>
            </thead>
            <tbody>
              {trucks.data.map((truck) => (
                <tr key={truck.truck_id} className="table-row">
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(truck.truck_id)}
                      onChange={() => handleCheckboxChange(truck.truck_id)}
                    />
                  </td>
                  <td>{truck.make}</td>
                  <td>{truck.model}</td>
                  <td>${truck.price}</td>
                  <td>{truck.batt_cap}</td>
                  <td>{truck.operation_range}</td>
                  <td>${truck.incentives}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="container-flex-col">
          <h3>Time-of-use rates</h3>
          <div className="container-flex-row">
            <LineChartComponent
              xLabel="Time"
              yLabel="Energy Charge ($/kWh)"
              onDataUpdate={handleEnergyChargeDataUpdate}
              initialData={initialEnergyChargeData}
            />
          </div>
        </div>
        <div className="container-flex-col">
          <h3>Advanced parameters</h3>
          <div>
            {parameterGroups.map((group) => (
              <div key={group.category} className={`dashboard-container ${group.category.toLowerCase().replace(/ /g, "-")}`}>
                <h3>{group.category}</h3>
                {group.inputs.map((input) => (
                  <ParameterInput
                    key={input.id}
                    label={input.label}
                    description={input.description}
                    id={input.id}
                    value={parameters[input.id] || ""}
                    onChange={(e) => handleInputChange(input.id, e.target.value)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="container-flex-row btn-container">
          <button className="optimize-btn" onClick={handleOptimize}>Optimize Temp 0</button>
        </div>
        {loadingOverlay && (
          <div>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default Parameters;
