import React, { useState, useEffect } from "react";
import "./RunHistoryTable.css";
import axios from "axios";

export const RunHistoryTable = ({
//   selectedRoutes,
//   onRouteSelect,
//   lastMapSelection,
}) => {
  const [tableData, setTableData] = useState([]);
//   const [highlightedRow, setHighlightedRow] = useState(null);
  const authToken = localStorage.getItem("authToken");
  // const rowRefs = useRef({});

  //TODO: Verify this section did not break anything
//   const scrollToRow = (route_id) => {
//     if (rowRefs.current[route_id]) {
//       setHighlightedRow(route_id);
//       rowRefs.current[route_id].scrollIntoView({
//         behavior: "smooth",
//         block: "center",
//       });

//       setTimeout(() => {
//         setHighlightedRow(null);
//       }, 500);
//     }
//   };

//   useEffect(() => {
//     if (lastMapSelection && selectedRoutes[lastMapSelection]) {
//       scrollToRow(lastMapSelection);
//     }
//   }, [lastMapSelection, selectedRoutes]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getalloptimizations/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          const sortedData = response.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          setTableData(sortedData);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="charger-header">Previous calculations</h3>
      </div>
      <table className="table">
        <thead>
          {/*TODO: Modify route columns, headers should be the same 
          as the entries in the database. i.e. trj_id, truck*/ }
          <tr className="table-header">
            <th>ID</th>
            <th>Name</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((optimization, index) => (
            <tr
            //   ref={(el) => (rowRefs.current[optimization.name] = el)}
            //   className={`table-content ${
            //     selectedRoutes[optimization.name] ? "selected" : ""
            //   }`}
              key={`${optimization.case_name}-${index}`}
            >
              <td>
                <input
                  type="checkbox"
                //   checked={selectedRoutes[optimization.name] || false}
                //   onChange={() => onRouteSelect(optimization.name)}
                  className="charger-checkbox"
                />
              </td>
              <td>{optimization.case_name}</td>
              <td>{new Date(optimization.timestamp).toLocaleString()}</td>
              <td>
                <button className="view-button">View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
