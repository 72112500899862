import "../../App.css";
import "./dashboard.css"
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { RoutingTable } from "../../components/pro_calculator/tables/RoutingTable";
import { ChargerTable } from "../../components/pro_calculator/tables/ChargerTable";
import { DepotTable } from "../../components/pro_calculator/tables/DepotTable";
import { Popup } from "reactjs-popup";
import { PiInfo } from "react-icons/pi";
import { AddButton } from "../../components/general/addButton";
import { DeleteButton } from "../../components/general/deleteButton";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

function Dashboard() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState({});
  const [selectTable, setSelectTable] = useState("route");
  const [lastMapSelection, setLastMapSelection] = useState(null);
  const [selectedRoutes, setSelectedRoutes] = useState({});
  const [coordinates, setCoordinates] = useState([]); 
  const [routes, setRoutes] = useState([]); 
  const [depots, setDepots] = useState([]);
  const [selectedDepots, setSelectedDepots] = useState({});
  const [showChargers, setShowChargers] = useState(false);
  const [showDepots, setShowDepots] = useState(false);
  const [circuitData, setCircuitData] = useState({});

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("authtoken", authToken);
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getprocessedroutes/", {
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setRoutes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching routes", error);
      });
  }, [authToken]);

  const handleSelectTable = (e) => {
    e.preventDefault();
    setSelectTable(e.target.value);
  }

  const handleChargerSelect = (chargerId, fromMap = false) => {
    const isSelecting = !selectedChargers[chargerId];
    setSelectedChargers((prev) => ({
      ...prev,
      [chargerId]: !prev[chargerId],
    }));
    if (fromMap && isSelecting) {
      setLastMapSelection(chargerId);
    }
  };

  const handleRouteSelect = async (routeId, fromView = false) => {
    const isSelecting = fromView ? true : !selectedRoutes[routeId];

    if (fromView && selectedRoutes[routeId]) {
      return;
    }

    setSelectedRoutes((prev) => ({
      ...prev,
      [routeId]: isSelecting,
    }));

    if (fromView) {
      setLastMapSelection(routeId);
    }

    if (isSelecting) {
      try {
        const selectedRoute = routes.find((route) => route.id === routeId);
        if (!selectedRoute) {
          console.error("Route not found in routes list");
          return;
        }

        const response = await axios.get(`http://localhost:8000/api/getrawroute/`, {
          params: { route_id: selectedRoute.raw_route },
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (
          response.data &&
          response.data.links &&
          response.data.links[0] &&
          response.data.links[0].coordinates
        ) {
          const routeCoordinates = response.data.links[0].coordinates.map(([lon, lat]) => ({
            lat: lat,
            lon: lon,
          }));
          setCoordinates(routeCoordinates);
        } else {
          console.error("Unexpected data structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching raw route data:", error);
      }
    }
  };

  // Field definitions
  const route_fields = [
    { name: "Route name", type: "string", id: "route_name" },
    { name: "Origin", type: "address", id: "origin" },
    { name: "Departure time", type: "time", id: "start_time" }
  ];

  const depot_fields = [
    { name: "Depot Name", type: "string", id: "depot_name" },
    { name: "Address", type: "address", id: "address" },
    //{ name: "Area", type: "number", id: "area" },
    //{ name: "Utility", type: "string", id: "utility" },
    //{ name: "Power Capacity", type: "number", id: "power_capacity" },
  ];

  const charger_fields = [
    { name: "Charging Station Name", type: "string", id: "charger-name" },
  ];

  // Handlers
  const handleAddRoute = async (formData) => {
    try {
      const stops = [];

      // Gather the stops into an array 
      Object.keys(formData).forEach((key) => {
        if (!isNaN(key)) {
          stops[parseInt(key, 10)] = formData[key];
        }
      })

      stops.unshift(formData.origin);

      const routeData = {
        start_time: formData.start_time,
        stops: stops,
        route_name: formData.route_name,
        idle_times: formData.idle_times,
      };

      console.log("FORM DATA", formData, routeData);

      const response = await axios.post(
        "http://localhost:8000/api/addroute/",
        routeData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        "Error adding route";
      console.error("Error adding route:", error);
    }
  };

  const handleAddDepot = async (formData) => {
    try {
      const depotData = {
        name: formData.depot_name,
        address: formData.address,
        //area: parseFloat(formData.area),
        //utility: formData.utility,
        //power_capacity: parseFloat(formData.power_capacity)
      };

      const response = await axios.post(
        "http://localhost:8000/api/adddepot/",
        depotData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        "Error adding depot";
      console.error("Error adding depot:", error);
    }
  };

  const fetchDepots = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/getdepot/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      setDepots(response.data);
    } catch (error) {
      console.error("Error fetching depots:", error);
    }
  };

  const handleDeleteDepot = async () => {
    const selectedDepotIds = Object.keys(selectedDepots).filter(depot_id => selectedDepots[depot_id]);

    if (selectedDepotIds.length === 0) return;

    try {
      const response = await axios.post(
        "http://localhost:8000/api/deletedepot/",
        { depot_ids: selectedDepotIds },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSelectedDepots({});
      fetchDepots(); // Refresh the depot list

    } catch (error) {
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        "Error deleting depot(s)";
      console.error("Error deleting depot(s):", error);
    }
  };

  const fetchCircuitData = async (depot) => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/getcircuits/",
        { circuit_names: depot.circuits },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCircuitData(prev => ({
        ...prev,
        [depot.depot_id]: response.data.circuits
      }));
    } catch (error) {
      console.error("Error fetching circuit data:", error);
    }
  };

  const handleDepotSelect = async (depot_id, fromMap = false) => {
    const isSelecting = !selectedDepots[depot_id];
    setSelectedDepots((prev) => ({
      ...prev,
      [depot_id]: !prev[depot_id],
    }));
    
    if (isSelecting) {
      const depot = depots.find(d => d.depot_id === depot_id);
      if (depot && depot.circuits && depot.circuits.length > 0) {
        await fetchCircuitData(depot);
      }
    }
    
    if (fromMap && isSelecting) {
      setLastMapSelection(depot_id);
    }
  };

  const handleShowChargers = (checked) => {
    setShowChargers(checked);
  }

  // Rendering table
  const renderTable = () => {
    switch (selectTable) {
      case "route":
        return <div>
          <RoutingTable 
            selectedRoutes={selectedRoutes} 
            onRouteSelect={handleRouteSelect} 
            lastMapSelection={lastMapSelection} 
            tableData={routes}
          />
          <AddButton
            entity={"route"}
            position={"bottom-left"}
            fields={route_fields}
            onSubmit={handleAddRoute}
            dynamicAddRoute={true}
          />
        </div>
      case "depot":
        return <div>
          <DepotTable 
            selectedDepots={ selectedDepots }
            onDepotSelect={ handleDepotSelect }
            lastMapSelection={ lastMapSelection }
          />
          <AddButton
            entity={"Depot"}
            position={"bottom"}
            fields={depot_fields}
            onSubmit={handleAddDepot}
          />
          <DeleteButton
            entity={"Depot(s)"}
            position={"bottom"}
            onDelete={handleDeleteDepot}
            selectedItems={depots.filter(depot => selectedDepots[depot.depot_id])}
          />
        </div>
      case "charger":
        return <div>
          <ChargerTable 
            selectedChargers={selectedChargers} 
            onChargerSelect={handleChargerSelect} 
            lastMapSelection={lastMapSelection} 
          />
          <AddButton
            entity={"charger"}
            position={"bottom-left"}
            fields={charger_fields}
          />
        </div>
      default:
        return <p>Loading ... </p>
    }
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <h1 className="title-label">Dashboard</h1>
        <div className="map-dashboard-container">
          <CustomMap
            selectedRoutes={ selectedRoutes }
            selectedEntities={selectedDepots}
            circuitData={ circuitData }
            onMarkerClick={handleDepotSelect}
            highlightedRoute={coordinates && coordinates.length > 0}
          />
        </div>
        <div className="dashboard-container">
          <form>
            <label>
              <button className={`table-select-button ${selectTable === "route" ? "selected" : ""}`} value="route" onClick={handleSelectTable}>Route</button>
            </label>
            <label>
              <button className={`table-select-button ${selectTable === "depot" ? "selected" : ""}`} value="depot" selected={selectTable === "depot"} onClick={handleSelectTable}>Depot</button>
            </label>
            <label>
              <button className={`table-select-button ${selectTable === "charger" ? "selected" : ""}`} value="charger" selected={selectTable === "charger"} onClick={handleSelectTable}>Charger</button>
            </label>
          </form>
        </div>
        <div className="dashboard-container">
          {renderTable()}
        </div>
        <div className="dashboard-container">
          <Popup trigger={
            <button className="info-button"> <PiInfo size={20} /></button>
          } modal nested>
            {close => (
              <div className="popup-container">
                <div className="close-container">
                  <button className="close" onClick={() => close()}>&times;</button>
                </div>
                <div className="popup-content">
                  <h3>Information</h3>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
